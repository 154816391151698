.details {
    max-height: 0;
    opacity: 0;
    padding: 0;
    transition: max-height 0.4s ease-out, padding 0.5s ease-out, opacity 0.3s ease-out;
}

.details.show {
    max-height: 500px;
    /* Adjust this value based on the expected height of the details section */
    opacity: 1;
    padding: 16px;
}

.details.hide {
    max-height: 0;
    opacity: 0;
}