@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* inter-latin-wght-normal */
@font-face {
  font-family: 'Inter Variable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(@fontsource-variable/inter/files/inter-latin-wght-normal.woff2)
    format('woff2-variations');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.root {
  font-family: 'Inter Variable';
}
/* HTML: <div class="loader"></div> */
/* .loader {
  --w: 10ch;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  letter-spacing: var(--w);
  width: var(--w);
  overflow: hidden;
  white-space: nowrap;
  color: #0000;
  animation: l40 1s infinite;
}
.loader:before {
  content: 'Brand Aura';
} */

@keyframes l40 {
  0%,
  100% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) 0 #000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }
  9% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) 0 #000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }
  18% {
    text-shadow: calc(0 * var(--w)) 0 #000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) -20px #0000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }
  27% {
    text-shadow: calc(0 * var(--w)) -20px #0000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) 0 #000,
      calc(-6 * var(--w)) -20px #0000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }
  36% {
    text-shadow: calc(0 * var(--w)) -20px #0000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) -20px #0000,
      calc(-6 * var(--w)) -20px #0000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) 0 #000, calc(-9 * var(--w)) 0 #000;
  }
  45% {
    text-shadow: calc(0 * var(--w)) -20px #0000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) 0 #000, calc(-5 * var(--w)) -20px #0000,
      calc(-6 * var(--w)) -20px #0000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) -20px #0000, calc(-9 * var(--w)) 0 #000;
  }
  54% {
    text-shadow: calc(0 * var(--w)) -20px #0000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) -20px #0000, calc(-5 * var(--w)) -20px #0000,
      calc(-6 * var(--w)) -20px #0000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) -20px #0000, calc(-9 * var(--w)) 0 #000;
  }
  63% {
    text-shadow: calc(0 * var(--w)) -20px #0000, calc(-1 * var(--w)) 0 #000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) -20px #0000, calc(-5 * var(--w)) -20px #0000,
      calc(-6 * var(--w)) -20px #0000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) -20px #0000, calc(-9 * var(--w)) -20px #0000;
  }
  72% {
    text-shadow: calc(0 * var(--w)) -20px #0000, calc(-1 * var(--w)) -20px #0000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) -20px #0000, calc(-5 * var(--w)) -20px #0000,
      calc(-6 * var(--w)) -20px #0000, calc(-7 * var(--w)) 0 #000,
      calc(-8 * var(--w)) -20px #0000, calc(-9 * var(--w)) -20px #0000;
  }
  81% {
    text-shadow: calc(0 * var(--w)) -20px #0000, calc(-1 * var(--w)) -20px #0000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) 0 #000,
      calc(-4 * var(--w)) -20px #0000, calc(-5 * var(--w)) -20px #0000,
      calc(-6 * var(--w)) -20px #0000, calc(-7 * var(--w)) -20px #0000,
      calc(-8 * var(--w)) -20px #0000, calc(-9 * var(--w)) -20px #0000;
  }
  90% {
    text-shadow: calc(0 * var(--w)) -20px #0000, calc(-1 * var(--w)) -20px #0000,
      calc(-2 * var(--w)) -20px #0000, calc(-3 * var(--w)) -20px #0000,
      calc(-4 * var(--w)) -20px #0000, calc(-5 * var(--w)) -20px #0000,
      calc(-6 * var(--w)) -20px #0000, calc(-7 * var(--w)) -20px #0000,
      calc(-8 * var(--w)) -20px #0000, calc(-9 * var(--w)) -20px #0000;
  }
}
/* HTML: <div class="loader"></div> */
/* .loader {
  font-weight: bold;
  font-family: monospace;
  display: inline-grid;
  font-size: 30px;
}
.loader:before,
.loader:after {
  content:"Brand Aura";
  grid-area: 1/1;
  -webkit-mask-size: 100% 5px,100% 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  animation: l35-1 1s infinite;
}
.loader:before {
  -webkit-mask-image:
    linear-gradient(#000 0 0),
    linear-gradient(#000 0 0);
}
.loader:after {
  -webkit-mask-image:
    linear-gradient(#000 0 0);
  animation:
    l35-1  1s infinite,
    l35-2 .2s infinite cubic-bezier(0.5,200,0.5,-200);
} */

@keyframes l35-1 {
  0% {
    -webkit-mask-position: 0 20px, 0 0;
  }
  20% {
    -webkit-mask-position: 0 8px, 0 0;
  }
  40% {
    -webkit-mask-position: 0 100%, 0 0;
  }
  60% {
    -webkit-mask-position: 0 3px, 0 0;
  }
  80% {
    -webkit-mask-position: 0 15px, 0 0;
  }
  100% {
    -webkit-mask-position: 0 0, 0 0;
  }
}
@keyframes l35-2 {
  100% {
    transform: translate(0.1px);
  }
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  width: 120px;
  height: 5px;
  border-radius: 15px;
  background: linear-gradient(#000 0 0) 0/0% no-repeat #ddd;
  animation: l1 0.5s infinite linear;
}
@keyframes l1 {
  100% {
    background-size: 100%;
  }
}

/* HTML: <div class="loader"></div> */
.button_loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}